import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import t1 from '../assets/t1.jpg'
import t2 from '../assets/t2.jpg'
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import email from '../assets/email.png';
import teleg from '../assets/telegram.png';
import discord from '../assets/discord.png';
import logo from '../assets/logo.png';
import csI from '../assets/cs.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const osL = () => {
    window.open("https://opensea.io/collection/zodiac-puppy");
}

const tweet = () => {
    window.open("https://twitter.com/zodiacpuppynft");
}

const discrd = () => {
    window.open("https://discord.gg/qR545rjkK2");
}

const tg = () => {
    window.open("https://t.me/ZodiacPuppyGroup");
}

const mail = () => {
    window.open("mailto:info@zodiacpuppy.com");
}

const cs = () => {
    window.open("https://www.coinscope.co/coin/zp");
}

class Team extends Component {

    render() {
        return (

            <div class="boxWrapTeam">
                <div class="conT2">TEAM</div>
                <div class="teamMain">
                    <div class="team">
                        <img src={t2} />
                        <div class="nameT">NFTYMOS</div>
                        <div class="positionT">FOUNDER/CEO</div>
                    </div>

                    <div class="team">
                        <img src={t1} />
                        <div class="nameT">HERMES</div>
                        <div class="positionT">COMMUNITY MANAGER</div>
                    </div>
                </div>

                <div class="boxWrap2Footer">

                    <div class="footer">

                        <img class="logoF" src={logo} />
                        <div class="icons">
                        <img onClick={osL} src={os} />
                        <img onClick={tweet} src={twitter} />
                        <img onClick={discrd} src={discord} />
                        <img onClick={tg} src={teleg} />
                        <img onClick={mail} src={email} />
                        <img onClick={cs} src={csI} />
                        </div>

                        <div class="copyright">Copyright © 2023 ZODIAC PUPPY. All Rights Reserved</div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Team;

