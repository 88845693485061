import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import hoverImg1 from '../assets/2.jpg';
import hoverImg2 from '../assets/1.jpg';
import hoverImg3 from '../assets/3.jpg';
import hoverImg4 from '../assets/1109.jpg';
import hoverImg5 from '../assets/5.jpg';
import hoverImg6 from '../assets/4.jpg';
import hoverImg7 from '../assets/3335.jpg';
import hoverImg8 from '../assets/6.jpg';
import hoverImg9 from '../assets/4558.jpg';
import hoverImg10 from '../assets/4160.jpg';
import hoverImg11 from '../assets/5211.jpg';
import hoverImg12 from '../assets/7.jpg';
import hoverImg13 from '../assets/13.jpg';
import hoverImg14 from '../assets/8.jpg';
import hoverImg15 from '../assets/12.jpg';
import hoverImg16 from '../assets/15.jpg';
import hoverImg17 from '../assets/11.jpg';
import hoverImg18 from '../assets/8165.jpg';
import hoverImg19 from '../assets/9620.jpg';
import hoverImg20 from '../assets/10.jpg';
import hoverImg21 from '../assets/9.jpg';
import hoverImg22 from '../assets/10020.jpg';
import hoverImg23 from '../assets/11550.jpg';
import hoverImg24 from '../assets/14.jpg';
import nft from '../assets/gif.mp4';

const Stry = () => {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, [])
}
class NftTypes extends Component {

  state = {
    _imageOpen: 0
  }

  imageOpen = async event => {
    event.preventDefault();

    this.setState({ _imageOpen: 1 });
  }

  render() {
    return (

      <div class="boxWrapNFT">

        <div class="about2">
          <div class="storyCon2">
            <div class="conT2">TYPES OF NFT</div>
            <p>- There is a max supply of 12’000 NFTs</p>
            <p>- There are 200 golden NFTs +800 regular NFTs of each zodiac</p>

            <div class="type">
              <div>
                <div class="typeH">REGULAR NFT</div>
                <div class="typeCon">Each Zodiac Puppy has a sign of his compatible zodiac on his chest colored with the power color of this zodiac, in addition to an indicator of his zodiac.</div>
              </div>
              <p></p>

              <div>
                <div class="typeH">GOLDEN NFT</div>
                <div class="typeCon">Golden nft are more rareable and the indicator is a golden one and has one golden trait that regular NFT doesn’t have.</div>
              </div>
            </div>
          </div>
          <div class="tableMain">
            <table>
              <tr>
                <th>Dog Breed</th>
                <th>Zodiac Sign</th>
                <th>Power Color</th>
                <th>Indicator</th>
                <th>Reguler NFT</th>
                <th>Golden NFT</th>
              </tr>
              <tr>
                <td>Lhasa Apso</td>
                <td>Aquarius</td>
                <td>Blue</td>
                <td>Pouring a jar of water</td>
                <td><img src={hoverImg1} /></td>
                <td><img src={hoverImg2} /></td>
              </tr>
              <tr>
                <td>Cavalier King</td>
                <td>Pisces</td>
                <td>Light Green</td>
                <td>"Little whale under his hand."</td>

                <td><img src={hoverImg3} /></td>
                <td><img src={hoverImg4} /></td>
              </tr>
              <tr>
                <td>German Shepherd</td>
                <td>Aries</td>
                <td>Red</td>
                <td>Aries horns</td>

                <td><img src={hoverImg5} /></td>
                <td><img src={hoverImg6} /></td>
              </tr>
              <tr>
                <td>Basset Hound</td>
                <td>Taurus</td>
                <td>Green</td>
                <td>Bull horns</td>

                <td><img src={hoverImg7} /></td>
                <td><img src={hoverImg8} /></td>
              </tr>
              <tr>
                <td>Russell Terrier</td>
                <td>Gemini</td>
                <td>Yellow</td>
                <td>"Two little puppies near him."</td>

                <td><img src={hoverImg9} /></td>
                <td><img src={hoverImg10} /></td>
              </tr>
              <tr>
                <td>Collie</td>
                <td>Cancer</td>
                <td>Silver</td>
                <td>"Cancer claws on his sides."</td>

                <td><img src={hoverImg11} /></td>
                <td><img src={hoverImg12} /></td>
              </tr>
              <tr>
                <td>Retriever</td>
                <td>Leo</td>
                <td>Orange</td>
                <td>Lion hair on his head</td>

                <td><img src={hoverImg13} /></td>
                <td><img src={hoverImg14} /></td>
              </tr>
              <tr>
                <td>Poodle</td>
                <td>Virgo</td>
                <td>Brown</td>
                <td>Angel wings</td>

                <td><img src={hoverImg15} /></td>
                <td><img src={hoverImg16} /></td>
              </tr>
              <tr>
                <td>Newfoundland</td>
                <td>Libra</td>
                <td>Pink</td>
                <td>Scales on his sides</td>

                <td><img src={hoverImg17} /></td>
                <td><img src={hoverImg18} /></td>
              </tr>
              <tr>
                <td>Pit Bull</td>
                <td>Scorpio</td>
                <td>Black</td>
                <td>Scorpion tail</td>

                <td><img src={hoverImg19} /></td>
                <td><img src={hoverImg20} /></td>
              </tr>
              <tr>
                <td>Husky</td>
                <td>Sagittarius</td>
                <td>Purple</td>
                <td>"Shooting arrow from his bow."</td>

                <td><img src={hoverImg21} /></td>
                <td><img src={hoverImg22} /></td>
              </tr>
              <tr>
                <td>Shiba</td>
                <td>Capricorn</td>
                <td>Gray</td>
                <td>Capricorn horns</td>

                <td><img src={hoverImg23} /></td>
                <td><img src={hoverImg24} /></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default NftTypes;

