import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/gif.mp4';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

let currentIndex = 0;
const listMainSections = document.getElementsByClassName("listMain");

function slideSections() {
    currentIndex = (currentIndex + 1) % listMainSections.length;
    const currentSection = listMainSections[currentIndex];

    // Slide the current section from left to right
    currentSection.style.left = "-300px";

    // Insert the next section after the current section
    const nextIndex = (currentIndex + 1) % listMainSections.length;
    const nextSection = listMainSections[nextIndex];
    nextSection.style.left = "300px";

    // Animate the sliding effect
    animate(currentSection, { left: "300px" }, 500, function () {
        currentSection.style.left = "0";
        nextSection.style.left = "0";
    });
}

// Simple animation function
function animate(element, properties, duration, callback) {
    const start = performance.now();

    function step(timestamp) {
        const time = timestamp - start;

        Object.keys(properties).forEach(function (property) {
            const initialValue = parseFloat(getComputedStyle(element)[property]);
            const targetValue = parseFloat(properties[property]);
            const delta = (targetValue - initialValue) * (time / duration);
            element.style[property] = initialValue + delta + "px";
        });

        if (time < duration) {
            requestAnimationFrame(step);
        } else {
            callback();
        }
    }

    requestAnimationFrame(step);
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="about">
                    <div class="storyCon">
                        <div class="conT">ABOUT</div>
                        <p>Zodiac Puppy is a delightful voxel art NFT collection merging Western zodiac signs with adorable dog breeds. Discover the perfect harmony between astrology and canine companions. Each NFT captures the essence of a zodiac sign and its corresponding dog breed, showcasing their unique traits and compatibility.</p>
                        <p>While there is no scientific evidence supporting the compatibility between zodiac signs and specific dog breeds, some people believe that certain dog breeds align with the personality traits associated with different zodiac signs. These interpretations are subjective and vary depending on individual perspectives. </p>
                         <p>Here's a general overview that some people may consider:</p>

                    </div>

                    <video src={nft} autoPlay loop playsInline muted></video>

                </div>

                <div class="listContainer">
                    <div class="listContainer2">
                        <div class="listMain">
                            <div class="listH">Aquarius (January 20 - February 18): Lhasa Apso</div>
                            <div class="listCon">Both appreciate independence and tend to have a protective nature and intelligence.</div>
                        </div>

                        <div class="listMain">
                            <div class="listH">Pisces (February 19 - March 20): cavalier king Charles spaniel</div>
                            <div class="listCon">Both share qualities such as affection, adaptability, and sensitivity and appreciate emotional connections and thrive in a loving environment.</div>
                        </div>
                    </div>

                    <div class="listContainer2">
                        <div class="listMain">
                            <div class="listH">Aries (March 21 - April 19): German Shepherd</div>
                            <div class="listCon">Both are known for their leadership qualities and energetic nature, and They require an active and loyal companion.</div>
                        </div>

                        <div class="listMain">
                            <div class="listH">Taurus (April 20 - May 20): Basset Hound</div>
                            <div class="listCon">Both share traits like patience, loyalty, and a love for comfort and stability.</div>
                        </div>
                    </div>

                    <div class="listContainer2">
                        <div class="listMain">
                            <div class="listH">Gemini (May 21 - June 20): Russel terrier</div>
                            <div class="listCon">Both tend to be energetic, adaptable, and enjoy mental stimulation. They both appreciate the need for variety and excitement in their lives.</div>
                        </div>

                        <div class="listMain">
                            <div class="listH">Cancer (June 21 - July 22): collie</div>
                            <div class="listCon">Both tend to be loyal, strong, creative and value a sense of security and family.</div>
                        </div>
                    </div>

                    <div class="listContainer2">
                        <div class="listMain">
                            <div class="listH">Leo (July 23 - August 22): Retriever</div>
                            <div class="listCon">Both are charismatic, friendly, confident, and enjoy being the center of attention.</div>
                        </div>

                        <div class="listMain">
                            <div class="listH">Virgo (August 23 - September 22): Poodle</div>
                            <div class="listCon">Both are known for their intelligence, cleanliness, and attention to detail.</div>
                        </div>
                    </div>

                    <div class="listContainer2">
                        <div class="listMain">
                            <div class="listH">Libra (September 23 - October 22): newfoundland</div>
                            <div class="listCon">Both share a friendly Sociable Nature and Love for Companionship and prefer a calm and peaceful atmosphere.</div>
                        </div>

                        <div class="listMain">
                            <div class="listH">Scorpio (October 23 - November 21): pit bull</div>
                            <div class="listCon">Both are known for their intense loyalty and protective instincts towards their loved ones, have a strong will to overcome challenges.</div>
                        </div>
                    </div>

                    <div class="listContainer2">
                        <div class="listMain">
                            <div class="listH">Sagittarius (November 22 - December 21): husky</div>
                            <div class="listCon">Both are known as adventurous and free-spirited. They have a thirst for exploration and seek new experiences, value their independence and freedom.</div>
                        </div>

                        <div class="listMain">
                            <div class="listH">Capricorn (December 22 - January 19): Shiba</div>
                            <div class="listCon">Both are known for being independent, intelligent, and somewhat reserved. They tend to be loyal and devoted to their loved ones but can also exhibit a strong-willed nature. </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Story;

